<template>
<div class="cate-orange">
  <!-- title -->
  <h2>お知らせ</h2>

  <!-- list news -->
  <ul class="list-news"
      :class="{ 'margin-webview': (checkWebview() && pagination.total === 0) }">
    <li v-for="(item, index) in newsList"
        :key="index">
      <!-- link to detail -->
      <a @click="handleLink(item.id, item.destination_type, item.redirect_url)">
        <time>{{ item.start_datetime | formatDate('YYYY年MM月DD日') }}
          <span v-if="item.is_new">NEW</span>
        </time>
        <p v-html="item.title"/>
      </a>
    </li>
  </ul>

  <!-- message data empty -->
  <b v-if="pagination.total === 0">該当するデータが見つかりませんでした。</b>

  <!-- pagination -->
  <Pagination :total-page="pagination.totalPages"
              @change-page = changePage
              :current-page="pageActive" />
</div>
</template>

<script>
import store from '@/store'
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/Pagination'
import Common from '@/mixins/common.mixin'
import { PAGE_NATIVE } from '@/enum/title-header'

export default {
  name: 'NewsList',

  mixins: [Common],

  components: {
    Pagination
  },

  beforeRouteEnter (to, from, next) {
    let params = {
      page: 1,
      perPage: 20
    }
    if (to.query.page) {
      params = {
        ...params,
        page: to.query.page
      }
    }

    store.dispatch('news/getListNews', params).then(_ => next())
  },

  computed: {
    ...mapState('news', ['newsList', 'pagination']),
    ...mapState('auth', ['token'])
  },

  created () {
    this.pageActive = this.$route.query.page
      ? parseInt(this.$route.query.page) : this.pagination.currentPage
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  data () {
    return {
      params: {
        page: this.$route.query.page || 1,
        perPage: 20
      },
      pageActive: 1,
      hostName: process.env.VUE_APP_USER_DOMAIN,
      PAGE_NATIVE
    }
  },

  methods: {
    ...mapActions('news', ['getListNews', 'getNewsDetail']),

    handleLink (id, type, url) {
      if (type === 1 && !url) {
        this.$router.push({ name: 'Profile.News.Detail', params: { id: id }, query: { page: this.params.page } })
      } else {
        this.getNewsDetail(id)

        // hidden new icon
        const index = this.newsList.findIndex(item => item.id === id)
        if (index > -1) {
          this.newsList[index].is_new = 0
        }

        //  CHECK URL INTERNAL OR EXTERNAL
        const parseUrl = new URL(url)

        // REDIRECT PAGE NATIVE
        let flagPageNative = false
        PAGE_NATIVE.forEach((elm) => {
          if (elm.path === parseUrl.pathname) {
            flagPageNative = true
          }
        })
        if (this.hostName.includes(parseUrl.origin)) {
          if (flagPageNative) {
            const namePageNative = PAGE_NATIVE.find(x => x.path === parseUrl.pathname).name
            // CHECK URL IS LINK TO LOGIN OR REGISTER
            if (this.token &&
              (namePageNative === 'Login' || namePageNative === 'Register')) {
              this.openPageNative('Index')
            } else this.openPageNative(namePageNative)
          } else {
            if (!this.checkWebview()) {
              if (url.includes('/lp/')) {
                window.open(url, '_self')
              } else if (url.includes('/cp/')) {
                window.open(url, '_blank')
              } else {
                this.$router.push({ path: parseUrl.pathname })
              }
            } else {
              // IF URL INTERNAL OPEN IN WEBVIEW
              if (this.checkWebview()) {
                this.triggerEventApp(
                  'OPEN_WEBVIEW',
                  { url: url }
                )
              }
            }
          }
        } else if (this.checkWebview()) {
          this.triggerEventApp(
            'OPEN_BROWSER',
            { url: url }
          )
        } else window.open(url, '_blank')
      }
    },

    changePage (page) {
      this.params = {
        ...this.params,
        page: page
      }

      this.pageActive = page
      this.fetchList(this.params)
    },

    // FETCH LIST
    fetchList (params = {}) {
      this.$router.push({ query: { page: params.page } })
      this.getListNews(params)

      // scroll to top when change page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: $font-family-title;
  @include font-size(36px);
  text-align: center;
  color: #C66C44;
  font-weight: normal;
  margin-bottom: 120px;
  line-height: 1;
  @media #{$info-phone} {
    font-size: 32px;
    margin-bottom: 94px;
  }
}

.list-news {
  margin-bottom: 57px;
  @media #{$info-phone} {
    margin-bottom: 52px;
  }
  &.margin-webview {
    margin-bottom: 0;
  }
  li {
    padding: 22px 25px 26px;
    background: rgba(#E7E5DE, 0.4);
    border-radius: 6px;
    font-family: $font-family-title;
    margin-bottom: 21px;
    @media #{$info-phone} {
      padding: 25px 17px 23px 21px;
      margin-bottom: 10px;
    }
    a {
      color: #282828;
      time {
        @include font-size(16px);
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        @media #{$info-phone} {
          font-size: 16px;
        }
        span {
          margin-left: 24px;
          padding: 0px 7px 1px;
          color: #ffffff;
          background: #E74C3C;
          border-radius: 4px;
          @media #{$info-phone} {
            margin-left: 20px;
          }
        }
      }
      p {
        font-family: $font-family-title-R;
        @include font-size(16px);
        word-break: break-all;
      }
    }
  }
}
</style>
